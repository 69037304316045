<mat-card>
  <mat-card-content>
    <form class="form-horizontal" [formGroup]="form">
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Gebruikersnaam</mat-label>
          <input matInput placeholder="Gebruikersnaam" formControlName="username" required>
        </mat-form-field>
      </div>
      <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>Wachtwoord</mat-label>
        <input matInput placeholder="Wachtwoord" type="password" formControlName="password" required>
      </mat-form-field>
      </div>
      <button class="w-100 mt-2" mat-button mat-flat-button color="primary"
              (click)="login()" [disabled]="form.invalid">
        Inloggen
      </button>
    </form>
  </mat-card-content>
</mat-card>
