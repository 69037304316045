import {Component, OnInit} from '@angular/core';
import {Tournament} from "../../model/tournament";
import {Event} from "../../model/event";
import {Group} from "../../model/group";
import {Round} from "../../model/round";
import {TournamentService} from "../../service/tournament.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DecimalPipe, NgForOf} from "@angular/common";
import {TeamPipe} from "../../pipes/team-pipe";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {TitleService} from "../../service/title.service";

@Component({
  selector: 'app-round-overview',
  standalone: true,
  imports: [
    NgForOf,
    TeamPipe,
    DecimalPipe,
    MatButton,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    FullNamePipe
  ],
  providers: [
    TeamPipe,
    FullNamePipe
  ],
  templateUrl: './round-overview.component.html',
  styleUrl: './round-overview.component.scss'
})
export class RoundOverviewComponent implements OnInit {

  tournament: Tournament;
  event: Event;
  group: Group;
  round: Round;
  prevRound: Round | undefined;

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle("Rondeoverzicht");
    const tournamentId = this.route.snapshot.paramMap.get('id');
    let roundId = Number(this.route.snapshot.paramMap.get('roundId'));

    this.tournamentService.getById(Number(tournamentId)).subscribe(data => {
      this.tournament = data;
      for (let event of this.tournament.events) {
        for (let group of event.groups) {
          let roundIndex = 0;
          this.prevRound = undefined;
          for (let round of group.rounds) {
            roundIndex++;
            if (round.id == roundId) {
              this.event = event;
              this.group = group;
              this.round = round;
              return;
            }
            this.prevRound = round;
          }
        }
      }
    });
  }

}
