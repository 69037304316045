@if (player && tournamentRegistrations && allPlayers) {
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-card *ngFor="let tournamentRegistration of getTournamentRegistrations()" appearance="outlined" class="mb-3">
        <mat-card-header>
          <h6>{{ tournamentRegistration.name }}</h6>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngFor="let eventRegistration of tournamentRegistration.events">
            <div class="row event-row">
              <div class="col-md-2">
                <mat-checkbox [disabled]="!tournamentRegistration.editable" [(ngModel)]="eventRegistration.registered" (change)="updateModelWhenEventChecked(eventRegistration, $event)" name="registered">
                  {{ EventRegistration.getType(eventRegistration.type) }}
                </mat-checkbox>
              </div>
              <div class="col-md-4">
                <ng-container *ngIf="eventRegistration.doublesEvent">
                  <mat-form-field appearance="fill">
                    <mat-label>Partner</mat-label>
                    <mat-select [value]="eventRegistration.partner" [disabled]="!tournamentRegistration.editable || !eventRegistration.registered" [(ngModel)]="eventRegistration.partner">
                      <mat-option>Geen</mat-option>
                      <mat-option *ngFor="let player of getRelevantPlayers(eventRegistration.type)" [value]="player.id">
                        {{ player | fullName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="col-6"></div>
            </div>
          </ng-container>
        </mat-card-content>
        <mat-card-actions *ngIf="tournamentRegistration.editable">
            <button mat-button (click)="saveRegistration(tournamentRegistration, $event)" [disabled]="waitingForBackend">
              <mat-icon>save</mat-icon>
              Opslaan
            </button>
            <a mat-button routerLink="/players">
              <mat-icon>cancel</mat-icon>
              Annuleren
            </a>
        </mat-card-actions>
      </mat-card>
      <button mat-button (click)="this.showAll = true" *ngIf="!this.showAll">
        <mat-icon>search</mat-icon>
        Toon oude toernooien
      </button>
    </mat-card-content>
  </mat-card>
}
