import {Component, inject, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatButton, MatIconButton} from "@angular/material/button";
import {DatePipe, NgClass, NgForOf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {TeamPipe} from "../../pipes/team-pipe";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {Match} from "../../model/match";
import {MatFormField, MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Result} from "../../model/result";
import {MatGridList, MatGridTile, MatGridTileText} from "@angular/material/grid-list";
import {Round} from "../../model/round";
import {Group} from "../../model/group";
import {Game} from "../../model/game";

@Component({
  selector: 'app-match-result',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatDialogTitle,
    DatePipe,
    MatIcon,
    NgForOf,
    TeamPipe,
    MatInput,
    ReactiveFormsModule,
    FormsModule,
    MatFormField,
    MatGridList,
    MatGridTile,
    MatGridTileText,
    MatIconButton,
    NgClass
  ],
  providers: [
    FullNamePipe,
    TeamPipe
  ],
  templateUrl: './match-result.component.html',
  styleUrl: './match-result.component.scss'
})
export class MatchResultComponent {

  result: Result = new Result();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {match: Match, group: Group, round: Round}) {
    this.result.matchId = this.data.match.id;

    if (data.match.games.length == 0) {
      this.result.games.push(new Game());
      this.result.games.push(new Game());
      this.result.games.push(new Game());
    } else {
      for (let game of data.match.games) {
        this.result.games.push(game);
      }
      if (data.match.games.length == 2) {
        this.result.games.push(new Game());
      }
    }
  }

  readonly dialogRef = inject(MatDialogRef<MatchResultComponent>);

  set21(team: number, game: number) {
    if (team == 1) {
      this.result.games[game - 1].score1 = 21;
    }
    if (team == 2) {
      this.result.games[game - 1].score2 = 21;
    }

  }

  onAnnulerenClick() {
    this.dialogRef.close();
  }

  validateResult(): number {
    let valid : boolean = true;
    valid &&= this.gameValid(this.result.games[0].score1, this.result.games[0].score2);
    valid &&= this.gameValid(this.result.games[1].score1, this.result.games[1].score2);

    if (this.result.games[2].score1 != undefined && this.result.games[2].score2 != undefined) {
      valid &&= this.gameValid(this.result.games[2].score1, this.result.games[2].score2);
    }

    return valid ? this.matchResult(this.result) : 0;
  }

  gameValid(score1: number, score2: number): boolean {
    if (score1 == undefined) return false;
    if (score2 == undefined) return false;

    if (score1 < 0 || score1 > 30) return false;
    if (score2 < 0 || score2 > 30) return false;

    if (score1 == 21 && score2 <= 19) return true;
    if (score1 <= 19 && score2 == 21) return true;

    if (score1 == 30 && score2 == 29) return true;
    if (score1 == 29 && score2 == 30) return true;

    if (score1 >= 22 && score1 <= 30 && (score1 - score2) == 2) return true;
    if (score2 >= 22 && score2 <= 30 && (score2 - score1) == 2) return true;

    return false;
  }

  matchResult(result: Result): number {
    let gameBalance = 0;
    if (result.games[0].score1 < result.games[0].score2) {
      gameBalance--;
    } else {
      gameBalance++;
    }
    if (result.games[1].score1 < result.games[1].score2) {
      gameBalance--;
    } else {
      gameBalance++;
    }

    if (Math.abs(gameBalance) == 2 && (result.games[2].score1 != undefined || result.games[2].score2 != undefined)) {
      return 0;
    }

    if (result.games[2].score1 != undefined && result.games[2].score2 != undefined) {
      if (result.games[2].score1 < result.games[2].score2) {
        gameBalance--;
      } else {
        gameBalance++;
      }
    }
    return Math.sign(gameBalance);
  }
}
