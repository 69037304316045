import {Component, Input, OnInit} from '@angular/core';
import {MatAnchor} from "@angular/material/button";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'player-link',
  standalone: true,
  imports: [
    MatAnchor,
    RouterLink
  ],
  templateUrl: './player-link.component.html',
  styleUrl: './player-link.component.scss'
})
export class PlayerLinkComponent implements OnInit {

  player1_id: string;
  player1_name: string;
  player2_id: string;
  player2_name: string;
  player3_id: string;
  player3_name: string;

  message_parts: string[];


  constructor() {}

  @Input() validationMessage: string;

  ngOnInit(): void {
    this.parse(this.validationMessage);
  }

  parse(message: string) {
    this.message_parts = message.split(/\[.*?]/g);
    const matches = message.match(/\[(\d+)\|.*?]/g);
    if (matches) {
      if (matches[0]) {
        this.player1_id = matches[0].substring(1, matches[0].indexOf("|"));
        this.player1_name = matches[0].substring(matches[0].indexOf("|") + 1, matches[0].indexOf("]"));
      }
      if (matches[1]) {
        this.player2_id = matches[1].substring(1, matches[1].indexOf("|"));
        this.player2_name = matches[1].substring(matches[1].indexOf("|") + 1, matches[1].indexOf("]"));
      }
      if (matches[2]) {
        this.player3_id = matches[2].substring(1, matches[2].indexOf("|"));
        this.player3_name = matches[2].substring(matches[2].indexOf("|") + 1, matches[2].indexOf("]"));
      }
    }
  }
}
