import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatAnchor, MatButton, MatIconButton, MatMiniFabButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatToolbar} from "@angular/material/toolbar";
import {TitleService} from "./service/title.service";
import {Subscription} from "rxjs";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {UserService} from "./authentication/user.service";
import {TournamentService} from "./service/tournament.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, RouterLinkActive, MatAnchor, MatIcon, MatButton, MatToolbar, NgOptimizedImage, MatIconButton, MatMiniFabButton, MatMenuTrigger, MatMenu, MatMenuItem],
  providers: [TitleService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

  title: string;
  titleSubscription: Subscription;
  user: string;
  userSubscription: Subscription;

  constructor(
    protected userService: UserService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private titleService: TitleService,
    private tournamentService: TournamentService
  ) {
  }

  ngOnInit() {
    this.titleSubscription = this.titleService.currentTitle.subscribe(newTitle => this.title = newTitle);
    this.userSubscription = this.userService.currentUser.subscribe(newUser => this.user = newUser);
  }

  ngOnDestroy(): void {
    this.titleSubscription.unsubscribe();
  }

  logOut() {
    this.userService.removeUser();
    this.router.navigate(['/auth/login']);
  }

  addTestData() {
    this.tournamentService.addTestData().subscribe(data => {
      this.router.navigate(['/tournaments']);
    })
  }
}
