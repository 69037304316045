import {EventEmitter, Injectable, Output} from "@angular/core";
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {Router} from "@angular/router";
import {User} from "./user";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user?: User;

  private userEmitter = new BehaviorSubject('');
  currentUser = this.userEmitter.asObservable();

  constructor(
    private cookieService: SsrCookieService,
    private router: Router,
  ) {}

  public getUser(): User | undefined {
    const user = this.cookieService.get('swissuser');

    if (user) {
      this.user = JSON.parse(user);
      this.userEmitter.next(JSON.parse(user).username);
    } else {
      this.user = undefined;
    }

    return this.user;
  }

  public isLoggedIn(): boolean {
    const user = this.cookieService.get('swissuser');

    return !(user == undefined || false || user == "");
  }

  public setUser(user: User) {
    this.cookieService.set('swissuser', JSON.stringify(user));

    this.user = user;

    this.userEmitter.next(this.user.username);
  }

  public removeUser() {
    this.cookieService.delete('swissuser');
    this.user = undefined;
    this.router.navigate(['/tournaments']);
    this.userEmitter.next('');
  }
}
