export enum Strength {
  D5 = "5e divisie",
  D6 = "6e divisie",
  D7 = "7e divisie",
  D8 = "8e divisie",
  D9 = "9e divisie",
  DR = "recreatief"
}

export class Player {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  sex: string;
  birthday: string;
  phoneNumber: string
  email: string;
  club: string;
  strength: Strength;

}


