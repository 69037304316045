import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(error=>{
      if((error.status == 401 || error.status == 403) && !this.isLoginPage(request)){
        this.userService.removeUser();
      }
      // const errMsg = error.error.message || error.statusText;
      return throwError(() => error);
      // return throwError(()=> errMsg);
    }));
  }

  private isLoginPage(request: HttpRequest<any>){
    return request.url.includes("/authenticate")
  }
}
