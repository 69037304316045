import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'fullName',
  standalone: true
})
export class FullNamePipe implements PipeTransform {
  transform(person: any, args?: any): any {
    if (person) {
      return [person.firstName, person.middleName, person.lastName].filter(Boolean).join(" ");
    } else {
      return "";
    }
  }
}
