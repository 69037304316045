export class TournamentRegistration {
  id: number;
  name: string;
  editable: boolean;
  date: string;
  status: string;
  events: EventRegistration[];

  static getStatus(tournamentRegistration: TournamentRegistration): string {
    if (tournamentRegistration.status == "CLOSED") return "Afgerond";
    if (tournamentRegistration.status == "DIVIDED") return "Ingedeeld";
    if (tournamentRegistration.status == "DRAWN") return "Geloot";
    if (tournamentRegistration.status == "IN_PROGRESS") return "Bezig";
    return "Nieuw";
  }
}

export class EventRegistration {
  id: number;
  type: string;
  doublesEvent: boolean;
  registered: boolean;
  player?: number;
  partner?: number;

  static getType(type: string): string {
    if (type == "HE") return "Herenenkel";
    if (type == "HD") return "Herendubbel";
    if (type == "DD") return "Damesdubbel";
    if (type == "DE") return "Damesenkel";
    return "Gemengd dubbel";
  }

}
