import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: 'matchResult',
  standalone: true
})
export class MatchResultPipe implements PipeTransform {
  transform(match: any, args?: any): any {
    let result = `${match.games[0].score1} – ${match.games[0].score2}`;
    result += ` ${match.games[1].score1} – ${match.games[1].score2}`;

    if (match.games[2] != null) {
      result += ` ${match.games[2].score1} - ${match.games[2].score2}`;
    }

    return result;
  }
}
