import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: 'status',
  standalone: true
})
export class StatusPipe implements PipeTransform {
  transform(objectWithStatus: any, args?: any): any {
    if (objectWithStatus.status == 'NOT_STARTED') return 'Nog niet gestart';
    return "";
  }
}
