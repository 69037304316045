<form (ngSubmit)="savePlayer()">
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="row">
        <div class="col-md-5">
          <mat-form-field appearance="fill">
            <mat-label>Achternaam</mat-label>
            <input matInput [(ngModel)]="player.lastName" name="lastName" required>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="fill">
            <mat-label>Tussenvoegsel</mat-label>
            <input matInput [(ngModel)]="player.middleName" name="middleName">
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="fill">
            <mat-label>Voornaam</mat-label>
            <input matInput [(ngModel)]="player.firstName" name="firstName" required>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-radio-group [(ngModel)]="player.sex" name="sex" required>
            <mat-label>Geslacht</mat-label>
            <mat-radio-button value="M">M</mat-radio-button>
            <mat-radio-button value="V">V</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label>Telefoon</mat-label>
            <input matInput [(ngModel)]="player.phoneNumber" name="phoneNumber" required>
          </mat-form-field>
        </div>
        <div class="col-md-9">
          <mat-form-field appearance="fill">
            <mat-label>Emailadres</mat-label>
            <input matInput [(ngModel)]="player.email" name="email" required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label>Geboortedatum</mat-label>
            <input matInput mask="00-00-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false" [(ngModel)]="player.birthday" type="text" name="birthday" required>
            <mat-hint>dd-mm-jjjj</mat-hint>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="fill">
            <mat-label>Club</mat-label>
            <input matInput [(ngModel)]="player.club" name="club">
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="fill">
            <mat-label>Speelsterkte</mat-label>
            <mat-select [(ngModel)]="player.strength" name="strength" required>
              <mat-option *ngFor="let strengthOption of Strength | keyvalue" [value]="strengthOption.key">
                {{ strengthOption.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button>
        <mat-icon>save</mat-icon>
        {{ isEditMode ? 'Bijwerken' : 'Opslaan' }}
      </button>
      <a mat-button routerLink="/players">
        <mat-icon>cancel</mat-icon>
        Annuleren
      </a>
    </mat-card-actions>
  </mat-card>
</form>
