import { Pipe, PipeTransform } from '@angular/core';
import {FullNamePipe} from "../pipes/fullname-pipe";
@Pipe({
  name: 'teamText',
  standalone: true
})
export class TeamPipe implements PipeTransform {

  constructor(private fullNamePipe: FullNamePipe) {}

  transform(team: any, args?: any): any {
    if (team.player2 != null) {
      // return this.player1.getFullName() + " / " + this.player2.getFullName();
      return this.fullNamePipe.transform(team.player1) + " / " + this.fullNamePipe.transform(team.player2);
    }
    // return this.player1.getFullName();
    return this.fullNamePipe.transform(team.player1);
  }
}
