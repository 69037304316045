//! moment.js locale configuration
//! locale : Breton [br]
//! author : Jean-Baptiste Le Duigou : https://github.com/jbleduigou

;
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' && typeof require === 'function' ? factory(require('../moment')) : typeof define === 'function' && define.amd ? define(['../moment'], factory) : factory(global.moment);
})(this, function (moment) {
  'use strict';

  function relativeTimeWithMutation(number, withoutSuffix, key) {
    var format = {
      'mm': 'munutenn',
      'MM': 'miz',
      'dd': 'devezh'
    };
    return number + ' ' + mutation(format[key], number);
  }
  function specialMutationForYears(number) {
    switch (lastNumber(number)) {
      case 1:
      case 3:
      case 4:
      case 5:
      case 9:
        return number + ' bloaz';
      default:
        return number + ' vloaz';
    }
  }
  function lastNumber(number) {
    if (number > 9) {
      return lastNumber(number % 10);
    }
    return number;
  }
  function mutation(text, number) {
    if (number === 2) {
      return softMutation(text);
    }
    return text;
  }
  function softMutation(text) {
    var mutationTable = {
      'm': 'v',
      'b': 'v',
      'd': 'z'
    };
    if (mutationTable[text.charAt(0)] === undefined) {
      return text;
    }
    return mutationTable[text.charAt(0)] + text.substring(1);
  }
  var br = moment.defineLocale('br', {
    months: 'Genver_C\'hwevrer_Meurzh_Ebrel_Mae_Mezheven_Gouere_Eost_Gwengolo_Here_Du_Kerzu'.split('_'),
    monthsShort: 'Gen_C\'hwe_Meu_Ebr_Mae_Eve_Gou_Eos_Gwe_Her_Du_Ker'.split('_'),
    weekdays: 'Sul_Lun_Meurzh_Merc\'her_Yaou_Gwener_Sadorn'.split('_'),
    weekdaysShort: 'Sul_Lun_Meu_Mer_Yao_Gwe_Sad'.split('_'),
    weekdaysMin: 'Su_Lu_Me_Mer_Ya_Gw_Sa'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'h[e]mm A',
      LTS: 'h[e]mm:ss A',
      L: 'DD/MM/YYYY',
      LL: 'D [a viz] MMMM YYYY',
      LLL: 'D [a viz] MMMM YYYY h[e]mm A',
      LLLL: 'dddd, D [a viz] MMMM YYYY h[e]mm A'
    },
    calendar: {
      sameDay: '[Hiziv da] LT',
      nextDay: '[Warc\'hoazh da] LT',
      nextWeek: 'dddd [da] LT',
      lastDay: '[Dec\'h da] LT',
      lastWeek: 'dddd [paset da] LT',
      sameElse: 'L'
    },
    relativeTime: {
      future: 'a-benn %s',
      past: '%s \'zo',
      s: 'un nebeud segondennoù',
      m: 'ur vunutenn',
      mm: relativeTimeWithMutation,
      h: 'un eur',
      hh: '%d eur',
      d: 'un devezh',
      dd: relativeTimeWithMutation,
      M: 'ur miz',
      MM: relativeTimeWithMutation,
      y: 'ur bloaz',
      yy: specialMutationForYears
    },
    dayOfMonthOrdinalParse: /\d{1,2}(añ|vet)/,
    ordinal: function (number) {
      var output = number === 1 ? 'añ' : 'vet';
      return number + output;
    },
    week: {
      dow: 1,
      // Monday is the first day of the week.
      doy: 4 // The week that contains Jan 4th is the first week of the year.
    }
  });
  return br;
});