import { Routes } from '@angular/router';
import { PlayerListComponent} from "./components/player-list/player-list.component";
import {PlayerEditComponent} from "./components/player-edit/player-edit.component";
import {TournamentListComponent} from "./components/tournament-list/tournament-list.component";
import {TournamentEditComponent} from "./components/tournament-edit/tournament-edit.component";
import {PlayerRegistrationsComponent} from "./components/player-registrations/player-registrations.component";
import {TournamentRegistrationsComponent} from "./components/tournament-registrations/tournament-registrations.component";
import {TournamentValidateComponent} from "./components/tournament-validate/tournament-validate.component";
import {TournamentDivideComponent} from "./components/tournament-divide/tournament-divide.component";
import {TournamentDrawComponent} from "./components/tournament-draw/tournament-draw.component";
import {TournamentManageComponent} from "./components/tournament-manage/tournament-manage.component";
import {MatchSheetsComponent} from "./components/match-sheets/match-sheets.component";
import {RoundOverviewComponent} from "./components/round-overview/round-overview.component";
import {AuthGuard} from "./authentication/authguard";
import {LoginComponent} from "./components/login/login.component";

export const routes: Routes = [
  { path: '', component: TournamentListComponent, canActivate: [AuthGuard], title: 'Toernooien' },
  { path: 'tournaments', component: TournamentListComponent, canActivate: [AuthGuard], title: 'Toernooien' },
  { path: 'tournaments/add', component: TournamentEditComponent, canActivate: [AuthGuard], title: 'Nieuw Toernooi' },
  { path: 'tournaments/:id/edit', component: TournamentEditComponent, canActivate: [AuthGuard], title: 'Bewerk Toernooi' },
  { path: 'tournaments/:id/registrations', component: TournamentRegistrationsComponent, canActivate: [AuthGuard], title: 'Inschrijvingen' },
  { path: 'tournaments/:id/validate', component: TournamentValidateComponent, canActivate: [AuthGuard], title: 'Toernooi' },
  { path: 'tournaments/:id/divide', component: TournamentDivideComponent, canActivate: [AuthGuard], title: 'Toernooi valideren' },
  { path: 'tournaments/:id/draw', component: TournamentDrawComponent, canActivate: [AuthGuard], title: 'Toernooi loten' },
  { path: 'tournaments/:id/manage', component: TournamentManageComponent, canActivate: [AuthGuard] },
  { path: 'tournaments/:id/manage/:tab', component: TournamentManageComponent, canActivate: [AuthGuard], title: 'Toernooien' },
  { path: 'players', component: PlayerListComponent, canActivate: [AuthGuard], title: 'Spelers' },
  { path: 'players/add', component: PlayerEditComponent, canActivate: [AuthGuard], title: 'Nieuwe Speler' },
  { path: 'players/edit/:id', component: PlayerEditComponent, canActivate: [AuthGuard], title: 'Bewerk Speler' },
  { path: 'players/:id/registrations', component: PlayerRegistrationsComponent, canActivate: [AuthGuard], title: 'Inschrijvingen' },
  { path: 'tournaments/:id/rounds/:roundId/matchsheets', component: MatchSheetsComponent, canActivate: [AuthGuard], title: 'Wedstrijdbriefjes' },
  { path: 'tournaments/:id/rounds/:roundId/overview', component: RoundOverviewComponent, canActivate: [AuthGuard], title: 'Rondeoverzicht' },
  { path: 'auth/login', component: LoginComponent }
];
