import {Registration} from "./registration";
import {Group} from "./group";

export class Event {
  id: number;
  type: string;
  status: string;
  doublesEvent: boolean;
  registrations: Registration[];
  groups: Group[];

  static getType(type: string): string {
    if (type == "HE") return "Herenenkel";
    if (type == "HD") return "Herendubbel";
    if (type == "DD") return "Damesdubbel";
    if (type == "DE") return "Damesenkel";
    return "Gemengd dubbel";
  }
}
