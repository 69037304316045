import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Tournament} from "../../model/tournament";
import {TournamentService} from "../../service/tournament.service";
import {MatAnchor, MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {CurrencyPipe, NgForOf, registerLocaleData} from "@angular/common";
import nl from "@angular/common/locales/nl";
import {TitleService} from "../../service/title.service";
import {NgxMaskDirective, NgxMaskPipe} from "ngx-mask";
import {MatSnackBar} from "@angular/material/snack-bar";

registerLocaleData(nl);

@Component({
  selector: 'app-tournament-edit',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    MatAnchor,
    MatButton,
    MatIcon,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatFormField,
    MatInput,
    MatLabel,
    MatCardActions,
    MatRadioButton,
    MatRadioGroup,
    NgForOf,
    CurrencyPipe,
    MatHint,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    CurrencyPipe
  ],
  templateUrl: './tournament-edit.component.html',
  styleUrl: './tournament-edit.component.scss'
})
export class TournamentEditComponent implements OnInit {

  tournament: Tournament;
  isEditMode: boolean = false;

  constructor(
    private tournamentService: TournamentService,
    private currencyPipe: CurrencyPipe,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private _snackBar: MatSnackBar,
  ) {
    this.tournament = new Tournament();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.titleService.setTitle("Bewerk Toernooi");
      this.isEditMode = true;
      this.tournamentService.getById(Number(id)).subscribe(data => {
        this.tournament = data;
      })
    } else {
      this.titleService.setTitle("Nieuw Toernooi");
    }
  }

  saveTournament() {
    if (this.isEditMode) {
      this.tournamentService.update(this.tournament.id, this.tournament).subscribe(() => {
        this.router.navigate(['/tournaments']);
      });
    } else {
      this.tournamentService.save(this.tournament).subscribe({
        next: () => this.router.navigate(['/tournaments']),
        error: () => this._snackBar.open('Niet alle velden zijn correct gevuld.')
      });
    }
  }

  protected readonly Array = Array;

  setCostsPerEvent(number: number, $event: any) {
    let value : string = String($event);
    value = value.replace( /^\D+/g, ''); // replace all leading non-digits with nothing
    this.tournament.costsPerEvent[number] = Number(value);

  }
}
