<div class="container">
  <div class="row toolbar">
    <mat-toolbar>
      <img ngSrc="/shuttle.jpg" width="50" height="50" priority alt=""/>
<!--
      <button mat-mini-fab class="menu-button">
        <mat-icon class="menu-button-icon" style="color: white;">menu</mat-icon>
      </button>
-->
      <h5 class="m-3">{{ title }}</h5>
      <span class="spacer"></span>
      <a routerLink="/tournaments" mat-button>
        <mat-icon>list</mat-icon>
        Toernooien
      </a>
      <a routerLink="/players" mat-button>
        <mat-icon>group</mat-icon>
        Spelers
      </a>
      @if (this.userService.isLoggedIn()) {
        <button mat-flat-button [matMenuTriggerFor]="accountMenu">
          <mat-icon>person</mat-icon>
          {{ user }}
        </button>
        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item (click)="logOut()">
            <mat-icon>exit_to_app</mat-icon>
            Uitloggen
          </button>
          <button mat-menu-item (click)="addTestData()">
            <mat-icon>science</mat-icon>
            Testtoernooi toevoegen
          </button>
        </mat-menu>
      } @else {
        <button mat-flat-button disabled>
          <mat-icon>person</mat-icon>
          Niet ingelogd
        </button>
      }
    </mat-toolbar>
  </div>
  <div class="row mt-3">
    <router-outlet></router-outlet>
  </div>
</div>
