import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from "rxjs";
import { Player } from "../model/player";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  private readonly playersUrl: string;

  constructor(private http: HttpClient) {
    this.playersUrl = `${environment.backendUrl}/players`;
  }

  public getAll(): Observable<Player[]> {
    return this.http.get<Player[]>(this.playersUrl);
  }

  public getById(id: number): Observable<Player> {
    return this.http.get<Player>(`${this.playersUrl}/${id}`);
  }

  public save(player: Player) {
    return this.http.post<Player>(this.playersUrl, player);
  }

  public update(id: number, player: Player) {
    return this.http.put<Player>(`${this.playersUrl}/${id}`, player);
  }
}
