@if (round) {
  <ng-container *ngFor="let match of round.matches">
    <div class="nobreak">
    <mat-card appearance="outlined">
      <mat-card-content>
        <h6>{{ tournament.name }}</h6>
        <br>
        <br>
        <div class="row">
          <div class="col-6">
            <b>{{ match.team1 | teamText }}</b>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline">
              <input matInput>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline">
              <input matInput>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline">
              <input matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b>{{ match.team2 | teamText }}</b>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline">
              <input matInput>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline">
              <input matInput>
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="outline">
              <input matInput>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <u>Graag de winnaar omcirkelen</u>
          </div>
          <div class="col-6 text-end">
            {{ group.name }} {{ round.name }}
          </div>
        </div>

      </mat-card-content>
    </mat-card>
    </div>
    <br>
  </ng-container>
}
