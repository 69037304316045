import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {LoginCredentials} from "./loginCredentials";
import {TokenModel} from "./tokenModel";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly authUrl: string

  constructor(private http: HttpClient) {
    this.authUrl = `${environment.backendUrl}`
  }

  public login(loginCredentials: LoginCredentials) {
    return this.http.post<any>(`${this.authUrl}/authenticate`, loginCredentials);
  }

  public logout(tokenModel: TokenModel) {
    return this.http.post<string>(
      `${this.authUrl}/logout`,
      tokenModel
    );
  }

  public logoutEverywhere() {
    return this.http.post<string>(
      `${this.authUrl}/logout-everywhere`,
      undefined
    );
  }
}
