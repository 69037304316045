@if (tournament) {
  <form (ngSubmit)="saveTournament()">
    <mat-card appearance="outlined">
<!--
      <mat-card-header>
        <h5>{{ isEditMode ? 'Bewerk toernooi' : 'Toevoegen toernooi' }}</h5>
      </mat-card-header>
-->
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="fill">
              <mat-label>Naam</mat-label>
              <input matInput [(ngModel)]="tournament.name" name="name" required>
            </mat-form-field>
          </div>
          <div class="col-md-3"></div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field appearance="fill">
              <mat-label>Datum</mat-label>
              <input matInput mask="00-00-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false" [(ngModel)]="tournament.date" name="date" required>
              <mat-hint>dd-mm-jjjj</mat-hint>
            </mat-form-field>
          </div>
          <div class="col-md-9"></div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <label id="max-events-label">Max. aantal onderdelen per inschrijving</label>
            <mat-radio-group [(ngModel)]="tournament.maxEvents" aria-labelledby="max-events-label" name="maxEvents" required>
              <mat-radio-button [value]="2">2</mat-radio-button>
              <mat-radio-button [value]="3">3</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <mat-label>Kosten 1 onderdeel</mat-label>
              <input matInput name="costsPerEvent1" type="number" min="0" step="0.5" [(ngModel)]="tournament.costsPerEvent[0]" required>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <mat-label>Kosten 2 onderdelen</mat-label>
              <input matInput name="costsPerEvent2" type="number" min="0" step="0.5" [(ngModel)]="tournament.costsPerEvent[1]" required>
            </mat-form-field>
          </div>
        </div>
        @if (tournament.maxEvents == 3) {
          <div class="row">
            <div class="col-md-2">
              <mat-form-field appearance="fill">
                <mat-label>Kosten 3 onderdelen</mat-label>
                <input matInput name="costsPerEvent3" type="number" min="0" step="0.5" [(ngModel)]="tournament.costsPerEvent[2]" required>
              </mat-form-field>
            </div>
          </div>
        }
        <div class="row">
          <div class="col-md-2">
            <mat-form-field appearance="fill">
              <mat-label>Aantal banen</mat-label>
              <input matInput name="courts" type="number" min="1" [(ngModel)]="tournament.courts" required>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>
          <mat-icon>save</mat-icon>
          {{ isEditMode ? 'Bijwerken' : 'Opslaan' }}
        </button>
        <a mat-button routerLink="/tournaments">
          <mat-icon>cancel</mat-icon>
          Annuleren
        </a>
      </mat-card-actions>
    </mat-card>
  </form>
}
