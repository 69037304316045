import {AfterContentChecked, AfterContentInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from "@angular/common";
import {RouterLink} from "@angular/router";
import {Tournament} from "../../model/tournament";
import {TournamentService} from "../../service/tournament.service";
import {MatAnchor, MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatRow,
  MatTable, MatTableModule
} from "@angular/material/table";
import {MatMenuTrigger} from "@angular/material/menu";
import {TitleService} from "../../service/title.service";

@Component({
  selector: 'app-tournament-list',
  standalone: true,
  imports: [
    NgFor, RouterLink, NgIf, MatAnchor, MatIcon, MatCard, MatCardHeader, MatCardContent, MatButton, MatTable, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatCell, MatCellDef, MatHeaderRow, MatRow, MatTableModule, MatIconButton, MatMenuTrigger
  ],
  templateUrl: './tournament-list.component.html',
  styleUrl: './tournament-list.component.scss'
})
export class TournamentListComponent implements OnInit, AfterContentChecked {

  tournaments: Tournament[];

  constructor(
    private tournamentService: TournamentService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.tournamentService.getAll().subscribe(data => {
      this.tournaments = data;
    });
  }

  ngAfterContentChecked() {
    this.titleService.setTitle("Toernooien");
  }

  protected readonly Tournament = Tournament;

  clearDraw(tournament: Tournament) {
    this.tournamentService.clearDraw(tournament.id).subscribe(data => {
      this.tournamentService.getAll().subscribe(data => {
        this.tournaments = data;
      });
    })
  }

}
