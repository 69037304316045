<mat-card appearance="outlined" *ngIf="tournament">
  <mat-card-header>
    <h5>Inschrijvingen voor {{ tournament.name }}</h5>
  </mat-card-header>
  <mat-card-content>
    <mat-card *ngFor="let event of tournament.events" appearance="outlined" class="m-3">
      <mat-card-header>
        <h6>{{ TournamentEvent.getType(event.type) }} ({{ event.registrations.length}} inschrijvingen)</h6>
      </mat-card-header>
      <mat-card-content>
        <table class="table {{ event.doublesEvent ? 'w-100' : 'w-50' }}">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="w-25">Naam</th>
              <th scope="col" class="w-25">Club</th>
              <th *ngIf="event.doublesEvent" scope="col" class="w-25">Partner</th>
              <th *ngIf="event.doublesEvent" scope="col" class="w-25">Club</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let registration of event.registrations">
              <td class="align-middle">{{ registration.player | fullName }}</td>
              <td class="align-middle">{{ registration.player.club }}</td>
              <td *ngIf="event.doublesEvent" class="align-middle">{{ registration.partner | fullName }}</td>
              <td *ngIf="event.doublesEvent" class="align-middle">{{ registration.partner?.club }}</td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
