<mat-card appearance="outlined">
<!--
  <mat-card-header>
    <h5>Toernooien</h5>
  </mat-card-header>
-->
  <mat-card-content>
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Naam</th>
          <th scope="col">Datum</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let tournament of tournaments">
        <td class="align-middle">{{ tournament.id }}</td>
        <td class="align-middle"><a [routerLink]="['/tournaments', tournament.id, 'manage']">{{ tournament.name }}</a></td>
        <td class="align-middle">{{ tournament.date }}</td>
        <td class="align-middle">{{ Tournament.getStatus(tournament) }}</td>
        <td class="align-middle">
          <a mat-button [routerLink]="['/tournaments', tournament.id, 'edit']" *ngIf="Tournament.getStatus(tournament) != 'Afgerond'">
            <mat-icon>edit</mat-icon>
            Bewerk
          </a>
          <a mat-button [routerLink]="['/tournaments', tournament.id, 'registrations']">
            <mat-icon>group</mat-icon>
            Inschrijvingen
          </a>
          <a mat-button (click)="clearDraw(tournament)" *ngIf="Tournament.getStatus(tournament) == 'Geloot'">
            <mat-icon>safety_divider</mat-icon>
            Loting wissen
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <a mat-button routerLink="/tournaments/add">
      <mat-icon>add</mat-icon>
      Nieuw toernooi
    </a>
  </mat-card-content>
</mat-card>

