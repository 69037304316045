import {Component, OnInit} from '@angular/core';
import {Player, Strength} from "../../model/player";
import {PlayerService} from "../../service/player.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatIcon} from "@angular/material/icon";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatOption, MatSelect} from "@angular/material/select";
import {KeyValuePipe, NgForOf} from "@angular/common";
import {MatAnchor, MatButton} from "@angular/material/button";
import {TitleService} from "../../service/title.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxMaskDirective, NgxMaskPipe} from "ngx-mask";

@Component({
  selector: 'app-player-edit',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    MatFormField,
    MatInput,
    MatIcon,
    MatHint,
    MatLabel,
    MatRadioGroup,
    MatRadioButton,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardActions,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatSelect,
    MatOption,
    KeyValuePipe,
    NgForOf,
    MatButton,
    MatAnchor,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  templateUrl: './player-edit.component.html',
  styleUrl: './player-edit.component.scss'
})
export class PlayerEditComponent implements OnInit {
  player: Player;
  isEditMode: boolean = false;


  constructor(
    private playerService: PlayerService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private _snackBar: MatSnackBar,
  ) {
    this.player = new Player();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.titleService.setTitle("Bewerk Speler");
      this.isEditMode = true;
      this.playerService.getById(Number(id)).subscribe(data => {
        this.player = data;
      });
    } else {
      this.titleService.setTitle("Nieuwe Speler");
    }
  }

  savePlayer() {
    if (this.isEditMode) {
      this.playerService.update(this.player.id, this.player).subscribe(() => {
        this.router.navigate(['/players']);
      });
    } else {
      this.playerService.save(this.player).subscribe({
        next: () => this.router.navigate(['/players']),
        error: () => this._snackBar.open('Niet alle velden zijn correct gevuld.')
      });
    }
  }

  protected readonly Object = Object;
  protected readonly Strength = Strength;
}
