import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Tournament} from "../model/tournament";
import {TournamentValidation} from "../model/tournamentValidation";
import {Result} from "../model/result";
import { environment } from "../../environments/environment"

@Injectable({
  providedIn: 'root'
})
export class TournamentService {

  private readonly tournamentsUrl: string

  constructor(private http: HttpClient) {
    this.tournamentsUrl = `${environment.backendUrl}/tournaments`;
  }

  public getAll(): Observable<Tournament[]> {
    return this.http.get<Tournament[]>(this.tournamentsUrl);
  }

  public getById(id: number): Observable<Tournament> {
    return this.http.get<Tournament>(`${this.tournamentsUrl}/${id}`);
  }

  public getValidation(id: number): Observable<TournamentValidation> {
    return this.http.get<TournamentValidation>(`${this.tournamentsUrl}/${id}/validate`);
  }

  public divide(id: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${id}/divide`, null);
  }

  public clearDivision(id: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${id}/divide/clear`, null);
  }

  public save(tournament: Tournament) {
    return this.http.post<Tournament>(this.tournamentsUrl, tournament);
  }

  public update(id: number, tournament: Tournament) {
    return this.http.put<Tournament>(`${this.tournamentsUrl}/${id}`, tournament);
  }

  public draw(id: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${id}/draw`, null);
  }

  public clearDraw(id: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${id}/draw/clear`, null);
  }

  public startRound(tournamentId: number, roundId: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/rounds/${roundId}/start`, null);
  }

  public finishRound(tournamentId: number, roundId: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/rounds/${roundId}/finish`, null);
  }

  public finishGroup(tournamentId: number, groupId: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/groups/${groupId}/finish`, null);
  }
  public reopenGroup(tournamentId: number, groupId: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/groups/${groupId}/reopen`, null);
  }

  public newRound(tournamentId: number, groupId: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/groups/${groupId}/new`, null);
  }

  public startMatch(tournamentId: number, matchId: number, court: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/matches/${matchId}/start/${court}`, null);
  }

  public stopMatch(tournamentId: number, matchId: number): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/matches/${matchId}/stop`, null);
  }

  public saveResult(tournamentId: number, matchId: number, result: Result): Observable<Tournament> {
    return this.http.post<Tournament>(`${this.tournamentsUrl}/${tournamentId}/matches/${matchId}`, result);
  }

  public playerPaid(tournamentId: number, playerId: number, paid: boolean): Observable<void> {
    return this.http.patch<void>(`${this.tournamentsUrl}/${tournamentId}/players/${playerId}/paid/${paid}`, null);
  }

  public playerPresent(tournamentId: number, playerId: number, paid: boolean): Observable<void> {
    return this.http.patch<void>(`${this.tournamentsUrl}/${tournamentId}/players/${playerId}/present/${paid}`, null);
  }

  public addTestData(): Observable<void> {
    return this.http.get<void>(`${environment.backendUrl}/testdata`);
  }

}
