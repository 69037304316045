import {Component, OnInit} from '@angular/core';
import {Tournament} from "../../model/tournament";
import {TournamentService} from "../../service/tournament.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TournamentDivision} from "../../model/tournamentDivision";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {Event} from "../../model/event";
import {Player, Strength} from "../../model/player";
import {EventDivision} from "../../model/eventDivision";
import {FullNamePipe} from "../../pipes/fullname-pipe";

@Component({
  selector: 'app-tournament-divide',
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    NgIf,
    MatCardContent,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    NgForOf,
    KeyValuePipe,
    FullNamePipe,
    MatAccordion
  ],
  templateUrl: './tournament-divide.component.html',
  styleUrl: './tournament-divide.component.scss'
})
export class TournamentDivideComponent implements OnInit {
  tournament?: Tournament;

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.tournamentService.getById(Number(id)).subscribe(data => {
      this.tournament = data;
    });
    // this.tournamentService.getDivision(Number(id)).subscribe(data => {
    //   this.tournamentDivision = data;
    // });
  }

  protected readonly TournamentEvent = Event;

  getStrength(strength: string) {
    for (let [key, value] of Object.entries(Strength)) {
      if (key == strength) return value;
    }
    return "";
  }
}
