import {Event} from "./event";
import {TournamentPlayer} from "./tournamentPlayer";

export class Tournament {
  id: number;
  name: string;
  date: string;
  status: string;
  events: Event[];
  tournamentPlayers: TournamentPlayer[];
  maxEvents: number;
  costsPerEvent: number[] = [0, 0, 0];
  courts: number;

  static getStatus(tournament: Tournament): string {
    if (tournament.status == "CLOSED") return "Afgerond";
    if (tournament.status == "DIVIDED") return "Ingedeeld";
    if (tournament.status == "DRAWN") return "Geloot";
    if (tournament.status == "ONGOING") return "Bezig";
    return "Nieuw";
  }

}
