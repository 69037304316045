<mat-card appearance="outlined" *ngIf="tournament">
  <mat-card-header>
    <h5>Indeling voor {{ tournament.name }}</h5>
  </mat-card-header>
  <mat-card-content>
    <mat-card *ngFor="let event of tournament.events" appearance="outlined" class="m-3">
      <mat-card-header>
        <h6>Indeling {{ TournamentEvent.getType(event.type) }}</h6>
      </mat-card-header>
      <mat-card-content>
        <mat-accordion multi="true">
          <mat-expansion-panel *ngFor="let group of event.groups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ group.name }}&nbsp;<span class="badge text-bg-success">{{ group.teams.length }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="table {{ event.doublesEvent ? 'w-100' : 'w-50' }}">
              <thead class="thead-dark">
              <tr>
                <th scope="col" class="w-20">Naam</th>
                <th scope="col" class="w-20">Club</th>
                <th scope="col" class="w-10">Speelsterkte</th>
                <th *ngIf="event.doublesEvent" scope="col" class="w-20">Partner</th>
                <th *ngIf="event.doublesEvent" scope="col" class="w-20">Club</th>
                <th *ngIf="event.doublesEvent" scope="col" class="w-10">Speelsterkte</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let team of group.teams">
                <td class="align-middle">{{ team.player1 | fullName }}</td>
                <td class="align-middle">{{ team.player1.club }}</td>
                <td class="align-middle">{{ getStrength(team.player1.strength.valueOf()) }}</td>
                <td *ngIf="event.doublesEvent" class="align-middle">{{ team.player2 | fullName }}</td>
                <td *ngIf="event.doublesEvent" class="align-middle">{{ team.player2.club }}</td>
                <td *ngIf="event.doublesEvent" class="align-middle">{{ getStrength(team.player2.strength.valueOf()) }}</td>
              </tr>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
