import {Component, OnInit} from '@angular/core';
import {Tournament} from "../../model/tournament";
import {TournamentService} from "../../service/tournament.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {NgForOf, NgIf} from "@angular/common";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {Event} from "../../model/event";
import {TeamPipe} from "../../pipes/team-pipe";
import {FullNamePipe} from "../../pipes/fullname-pipe";

@Component({
  selector: 'app-tournament-draw',
  standalone: true,
  imports: [
    FullNamePipe,
    MatCard,
    NgIf,
    MatCardContent,
    MatCardHeader,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    NgForOf,
    TeamPipe,
    MatAccordion
  ],
  providers: [
    FullNamePipe,
    TeamPipe
  ],
  templateUrl: './tournament-draw.component.html',
  styleUrl: './tournament-draw.component.scss'
})
export class TournamentDrawComponent implements OnInit {

  tournament: Tournament;

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.tournamentService.draw(Number(id)).subscribe(data => {
      this.tournament = data;
    })
  }

  protected readonly TournamentEvent = Event;
}
