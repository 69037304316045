import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TournamentRegistration} from "../model/tournamentRegistration";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private readonly backendUrl: string;

  constructor(private http: HttpClient) {
    this.backendUrl = `${environment.backendUrl}`;
  }

  public getTournamentRegistrationsByPlayerId(id: number): Observable<TournamentRegistration[]> {
    return this.http.get<TournamentRegistration[]>(`${this.backendUrl}/players/${id}/registrations`)
  }

  public saveTournamentRegistrations(tournamentRegistration: TournamentRegistration, playerId: number) {
    return this.http.post<TournamentRegistration>(`${this.backendUrl}/players/${playerId}/registrations/${tournamentRegistration.id}`, tournamentRegistration);
  }

}
