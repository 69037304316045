import {Component, inject, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {Match} from "../../model/match";
import {NgForOf} from "@angular/common";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-court-selection',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgForOf,
    MatButton,
    MatDialogClose,
    MatDialogActions
  ],
  templateUrl: './court-selection.component.html',
  styleUrl: './court-selection.component.scss'
})
export class CourtSelectionComponent {

  court: number;

  readonly dialogRef = inject(MatDialogRef<CourtSelectionComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    match: Match,
    availableCourts: number[],
    totalCourts: number
  }) {}

  onAnnulerenClick() {
    this.dialogRef.close();
  }
}
