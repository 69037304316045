import {Component, OnInit} from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {Tournament} from "../../model/tournament";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {Event} from "../../model/event";
import {TournamentService} from "../../service/tournament.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {Player} from "../../model/player";
import {EventValidation, TournamentValidation, Validation} from "../../model/tournamentValidation";
import {MatIcon} from "@angular/material/icon";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {PlayerService} from "../../service/player.service";
import {PlayerLinkComponent} from "../player-link/player-link.component";

@Component({
  selector: 'app-tournament-validate',
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardContent,
    NgForOf,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    NgIf,
    MatAccordion,
    AsyncPipe,
    MatIcon,
    FullNamePipe,
    PlayerLinkComponent
  ],
  templateUrl: './tournament-validate.component.html',
  styleUrl: './tournament-validate.component.scss'
})
export class TournamentValidateComponent implements OnInit {
  tournament: Tournament;
  tournamentValidation: TournamentValidation;
  players: Player[];

  protected readonly TournamentEvent = Event;
  protected readonly Player = Player;
  protected readonly Validation = Validation;

  constructor(
    private tournamentService: TournamentService,
    private playerService: PlayerService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.tournamentService.getById(Number(id)).subscribe(data => {
      this.tournament = data;
    });
    this.tournamentService.getValidation(Number(id)).subscribe(data => {
      this.tournamentValidation = data;
    });
    this.playerService.getAll().subscribe(data => {
      this.players = data;
    });
  }

  getEventValidation(id: number): EventValidation | null {
    if (!this.tournamentValidation) {
      return null;
    } else {}
    return this.tournamentValidation.eventValidations.filter(eventValidation => eventValidation.eventId == id)[0];
  }

  getIconForSeverity(severity: string) {
    if (severity == 'INFO') {
      return "info";
    } else if (severity == 'WARN') {
      return "warning";
    } else {
      return "dangerous";
    }
  }

  getColorForSeverity(severity: string) {
    if (severity == 'INFO') {
      return "success";
    } else if (severity == 'WARN') {
      return "warning";
    } else {
      return "danger";
    }
  }

  tournamentHasErrors(): boolean {
    let hasErrors: boolean = Validation.hasErrors(this.tournamentValidation.validations);
    for (let eventValidation of this.tournamentValidation.eventValidations) {
      hasErrors &&= Validation.hasErrors(eventValidation.validations);
    }
    return hasErrors;
  }

}
