@if (this.message_parts[0]) {
  {{ this.message_parts[0] }}
} @else {&nbsp;}
@if (this.player1_id) {
  <a routerLink="/players/{{ this.player1_id}}/registrations">{{ this.player1_name }}</a>
}
@if (this.message_parts[1]) {
  {{ this.message_parts[1] }}
}
@if (this.player2_id) {
  <a routerLink="/players/{{ this.player2_id}}/registrations">{{ this.player2_name }}</a>
}
@if (this.message_parts[2]) {
  {{ this.message_parts[2] }}
}
@if (this.player3_id) {
  <a routerLink="/players/{{ this.player3_id}}/registrations">{{ this.player3_name }}</a>
}
@if (this.message_parts[3]) {
  {{ this.message_parts[3] }}
}
