@if (tournamentValidation && tournament) {
  <mat-card appearance="outlined" class="m-3">
    <mat-card-header>
      <h6>Toernooi</h6>
    </mat-card-header>
    <mat-card-content>
      <mat-expansion-panel [disabled]="tournamentValidation.validations.length == 0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Validaties&nbsp;<span class="badge {{ Validation.hasErrors(tournamentValidation.validations) ? 'text-bg-danger' : 'text-bg-success'}}">{{ tournamentValidation.validations.length }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li *ngFor="let validation of tournamentValidation.validations">
            <mat-icon class="text-{{ getColorForSeverity(validation.severity) }}">{{ getIconForSeverity(validation.severity) }}</mat-icon>
            {{ validation.message }}
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-card-content>
  </mat-card>
  <mat-card *ngFor="let event of tournament.events" appearance="outlined" class="m-3">
    <mat-card-header>
      <h6>{{ TournamentEvent.getType(event.type) }}</h6>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion multi="true">
        <mat-expansion-panel [disabled]="event.registrations.length == 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Inschrijvingen&nbsp;<span class="badge {{ event.registrations.length == 0 ? 'text-bg-danger' : 'text-bg-success'}}">{{ event.registrations.length }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table class="table {{ event.doublesEvent ? 'w-100' : 'w-50' }}">
            <thead class="thead-dark">
            <tr>
              <th scope="col" class="w-25">Naam</th>
              <th scope="col" class="w-25">Club</th>
              <th *ngIf="event.doublesEvent" scope="col" class="w-25">Partner</th>
              <th *ngIf="event.doublesEvent" scope="col" class="w-25">Club</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let registration of event.registrations">
              <td class="align-middle">{{ registration.player | fullName }}</td>
              <td class="align-middle">{{ registration.player.club }}</td>
              <td *ngIf="event.doublesEvent" class="align-middle">{{ registration.partner | fullName }}</td>
              <td *ngIf="event.doublesEvent" class="align-middle">{{ registration.partner?.club }}</td>
            </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
        <mat-expansion-panel [disabled]="getEventValidation(event.id)?.validations?.length == 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Validaties&nbsp;<span class="badge {{ Validation.hasErrors(getEventValidation(event.id)?.validations) ? 'text-bg-danger' : 'text-bg-success'}}">{{ getEventValidation(event.id)?.validations?.length }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let validation of getEventValidation(event.id)?.validations">
              <mat-icon class="text-{{ getColorForSeverity(validation.severity) }}">{{ getIconForSeverity(validation.severity) }}</mat-icon>
              <player-link [validationMessage]="validation.message"></player-link>
<!--
              <div *ngIf="validation.message">
                <app-player-link [validationMessage]="'validation.message'"></app-player-link>
              </div>
-->
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
}
