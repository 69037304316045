@if (round) {
  <h6>{{ group.name }}, {{ round.name }}:</h6>
  @if (round.status != 'FINISHED') {
    <table class="table table-sm m-4 wide w-100">
      <tbody>
        <tr *ngFor="let match of round.matches">
          <td class="align-middle" style="width: 45%;">{{ match.team1 | teamText }}</td>
          <td class="align-middle w-sep">-</td>
          <td class="align-middle" style="width: 45%;">{{ match.team2 | teamText }}</td>
          <td class="align-middle w-sep"></td>
        </tr>
        @if (round.drawnOut) {
          <tr>
            <td class="align-middle w-100" colspan="4"><b>Deze ronde uitgeloot:</b> {{ round.drawnOut | teamText }}</td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <table class="table table-sm m-4 wide w-100">
      <tbody>
      <tr *ngFor="let match of round.matches">
        <td class="align-middle" style="width: 30%;">
          @if (event.doublesEvent) {
            {{ match.team1.player1 | fullName }} /<br>{{ match.team1.player2 | fullName }}
          } @else {
            {{ match.team1.player1 | fullName }}
          }
        <td class="align-middle w-sep">-</td>
        <td class="align-middle" style="width: 30%;">
          @if (event.doublesEvent) {
            {{ match.team2.player1 | fullName }} /<br>{{ match.team2.player2 | fullName }}
          } @else {
            {{ match.team2.player1 | fullName }}
          }
        </td>
        <td class="align-middle" style="width: 35%;">
          <div class="row result align-items-center">
            <span *ngFor="let game of match.games" class="col-3">{{ game.score1 }}-{{ game.score2 }}</span>
            @if (match.games.length == 2) {
              <span class="col-3"></span>
            }
          </div>
        </td>
      </tr>
        @if (round.drawnOut) {
          <tr>
            <td class="align-middle w-100" colspan="4"><b>Deze ronde uitgeloot:</b> {{ round.drawnOut | teamText }}</td>
          </tr>
        }
      </tbody>
    </table>
  }

  @if (round.status == 'FINISHED' || prevRound) {
    @if (group.status == 'FINISHED') {
      <h6>Eindstand:</h6>
    } @else if (round.status == 'FINISHED') {
      <h6>Stand na {{ round.name }}:</h6>
    } @else {
      <h6>Stand na {{ prevRound?.name }}:</h6>
    }
    <table class="table table-sm w-100 m-4">
      <thead>
      <tr>
        <th>#</th>
        <th>
          @if (event.doublesEvent) {
            Team
          } @else {
            Speler
          }
        </th>
        <th>Gespeeld</th>
        <th>Punten/W</th>
        <th>Games/W</th>
        <th>#/W</th>
      </tr>
      </thead>
      <tbody class="table-group-divider">
      <tr *ngFor="let entry of round.standings.entries">
        <td class="align-middle">{{ entry.position }}</td>
        <td class="align-middle">{{ entry.team | teamText }}</td>
        <td class="align-middle">{{ entry.played }}</td>
        <td class="align-middle">{{ entry.points / entry.played | number: '1.0-2' }}</td>
        <td class="align-middle">{{ (entry.gamesWon - entry.gamesLost) / entry.played | number: '1.0-2' }}</td>
        <td class="align-middle">{{ (entry.pointsWon - entry.pointsLost) / entry.played | number: '1.0-2' }}</td>
      </tr>
      </tbody>
    </table>
  }
}
