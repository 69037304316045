import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Player} from "../../model/player";
import {PlayerService} from "../../service/player.service";
import {NgFor} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MatAnchor} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {TitleService} from "../../service/title.service";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, MatSortHeader} from "@angular/material/sort";

@Component({
  selector: 'app-player-list',
  standalone: true,
  imports: [NgFor, RouterLink, MatAnchor, MatIcon, MatCard, MatCardHeader, MatCardContent, FullNamePipe, MatTable, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatCell, MatCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatFormField, MatInput, MatFormFieldModule, MatPaginator, MatSortHeader, MatSort],
  providers: [FullNamePipe],
  templateUrl: './player-list.component.html',
  styleUrl: './player-list.component.scss'
})
export class PlayerListComponent implements AfterViewInit {

  players: Player[];

  displayedColumns: string[] = ['id', 'name', 'sex', 'club', 'action'];
  dataSource: MatTableDataSource<Player>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private titleService: TitleService,
    private playerService: PlayerService,
    private fullNamePipe: FullNamePipe) {
  }

  ngAfterViewInit() {
    this.titleService.setTitle("Spelers");
    this.playerService.getAll().subscribe(data => {
      this.players = data;
      this.dataSource = new MatTableDataSource(this.players);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = (item, header) => {
        switch (header) {
          case 'id': return item.id;
          case 'club': return item.club;
          default: return item.lastName + item.firstName;
        }
      }
      this.dataSource.sort = this.sort;
    });

    this.paginator._intl.itemsPerPageLabel = 'Spelers per pagina';
    this.paginator._intl.firstPageLabel = '';
    this.paginator._intl.previousPageLabel = '';
    this.paginator._intl.nextPageLabel = '';
    this.paginator._intl.lastPageLabel = '';
  }

  filterPlayersList(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  protected readonly Player = Player;
}
