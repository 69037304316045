<h2 mat-dialog-title>Uitslag invoeren</h2>
<mat-dialog-content>
  <mat-grid-list cols="5" rowHeight="12:7">

    <mat-grid-tile colspan="2">
      <div class="w-100" style="font-size: 16px;">
        <b>{{ data.group.name }} {{ data.round.name }}</b>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <button type="button" class="btn btn-primary btn-lg" (click)="set21(1, 1)">21</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button type="button" class="btn btn-primary btn-lg" (click)="set21(1, 2)">21</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button type="button" class="btn btn-primary btn-lg" (click)="set21(1, 3)">21</button>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <div class="w-100" [ngClass]="{'winner': validateResult() == 1}">
        {{ data.match.team1 | teamText }}
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <mat-form-field appearance="outline" (change)="validateResult()">
        <input matInput type="number" min="0" max="30" [(ngModel)]="result.games[0].score1">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="outline" (change)="validateResult()">
        <input matInput type="number" min="0" max="30" [(ngModel)]="result.games[1].score1">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="outline" (change)="validateResult()">
        <input matInput type="number" min="0" max="30" [(ngModel)]="result.games[2].score1">
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <div class="w-100" [ngClass]="{'winner': validateResult() == -1}">
        {{ data.match.team2 | teamText }}
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="outline" (change)="validateResult()">
        <input matInput type="number" min="0" max="30" [(ngModel)]="result.games[0].score2">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="outline" (change)="validateResult()">
        <input matInput type="number" min="0" max="30" [(ngModel)]="result.games[1].score2">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field appearance="outline" (change)="validateResult()">
        <input matInput type="number" min="0" max="30" [(ngModel)]="result.games[2].score2">
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>

    </mat-grid-tile>
    <mat-grid-tile>
    </mat-grid-tile>
    <mat-grid-tile>
      <button type="button" class="btn btn-primary btn-lg" (click)="set21(2, 1)">21</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button type="button" class="btn btn-primary btn-lg" (click)="set21(2, 2)">21</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button type="button" class="btn btn-primary btn-lg" (click)="set21(2, 3)">21</button>
    </mat-grid-tile>
  </mat-grid-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onAnnulerenClick()">Annuleren</button>
  <button mat-button [disabled]="validateResult() == 0" [mat-dialog-close]="result">Opslaan</button>
</mat-dialog-actions>
