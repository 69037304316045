<mat-card appearance="outlined">
  <mat-card-content>
    <mat-form-field appearance="outline">
      <mat-label>Filter spelers</mat-label>
      <input matInput (keyup)="filterPlayersList($event)" #input>
    </mat-form-field>
    <div>
      <table mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
          <td mat-cell *matCellDef="let player">{{ player.id }}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Naam</th>
          <td mat-cell *matCellDef="let player">{{ player | fullName }}</td>
        </ng-container>
        <ng-container matColumnDef="sex">
          <th mat-header-cell *matHeaderCellDef>M/V</th>
          <td mat-cell *matCellDef="let player">{{ player.sex }}</td>
        </ng-container>
        <ng-container matColumnDef="club">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Club</th>
          <td mat-cell *matCellDef="let player">{{ player.club }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let player">
            <a mat-button [routerLink]="['/players/edit', player.id]">
              <mat-icon>edit</mat-icon>
              Bewerk
            </a>
            <a mat-button [routerLink]="['/players', player.id, 'registrations']">
              <mat-icon>app_registration</mat-icon>
              Inschrijvingen
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
    <a mat-button routerLink="/players/add">
      <mat-icon>person_add</mat-icon>
      Nieuwe speler
    </a>
  </mat-card-content>
</mat-card>
