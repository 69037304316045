import {Component, OnInit} from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {Tournament} from "../../model/tournament";
import {TournamentService} from "../../service/tournament.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {Player} from "../../model/player";
import {Event} from "../../model/event";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {TitleService} from "../../service/title.service";

@Component({
  selector: 'app-tournament-registrations',
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardContent,
    NgForOf,
    NgIf,
    FullNamePipe
  ],
  templateUrl: './tournament-registrations.component.html',
  styleUrl: './tournament-registrations.component.scss'
})
export class TournamentRegistrationsComponent implements OnInit {

  tournament?: Tournament;

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Inschrijvingen");
    const id = this.route.snapshot.paramMap.get('id');
    this.tournamentService.getById(Number(id)).subscribe(data => {
      this.tournament = data;
    })
  }

  protected readonly TournamentEvent = Event;
}
