@if (tournament) {
  <mat-card appearance="outlined">
<!--
    <mat-card-header>
      <h5>{{ tournament.name }}</h5>
    </mat-card-header>
-->
    <mat-card-content>
      <mat-tab-group animationDuration="0ms" disableRipple="true">

        @if (tournament.status == 'UPCOMING') {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>playlist_add_check</mat-icon>
              &nbsp;Validaties
              <button mat-icon-button [matMenuTriggerFor]="upcomingTournamentMenu" class="menu-button m-3">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #upcomingTournamentMenu="matMenu">
                <button mat-menu-item (click)="divideTournament()">
                  <mat-icon>play_arrow</mat-icon>
                  Deel toernooi in
                </button>
              </mat-menu>
            </ng-template>
            <app-tournament-validate></app-tournament-validate>
          </mat-tab>
        }

        @if (tournament.status == 'DIVIDED') {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>safety_divider</mat-icon>
              &nbsp;Indeling
              <button mat-icon-button [matMenuTriggerFor]="dividedTournamentMenu" class="menu-button m-3">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #dividedTournamentMenu="matMenu">
                <button mat-menu-item (click)="clearDivision()">
                  <mat-icon>highlight_remove</mat-icon>
                  Indeling wissen
                </button>
                <button mat-menu-item (click)="drawTournament()">
                  <mat-icon>safety_divider</mat-icon>
                  1e ronde loten
                </button>
              </mat-menu>
            </ng-template>
            <mat-card appearance="outlined" class="m-3">
              <mat-card-header>
                <h6>Totaal: {{ getTournamentMatchCount(tournament)}} wedstrijden</h6>
              </mat-card-header>
            </mat-card>
            @for (event of tournament.events; track event) {
              @if (event.groups.length > 0) {
                <mat-card appearance="outlined" class="m-3">
                  <mat-card-header>
                    <h6>Indeling {{ TournamentEvent.getType(event.type) }} ({{ getEventMatchCount(event)}} wedstrijden)</h6>
                  </mat-card-header>
                  <mat-card-content>
                    <mat-accordion multi="true">
                      <mat-expansion-panel *ngFor="let group of event.groups">
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            {{ group.name }}&nbsp;<span class="badge text-bg-success">{{ group.teams.length }}</span>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <table class="table {{ event.doublesEvent ? 'w-100' : 'w-50' }}">
                          <thead class="thead-dark">
                          <tr>
                            <th scope="col" class="w-20">Naam</th>
                            <th scope="col" class="w-20">Club</th>
                            <th scope="col" class="w-10">Speelsterkte</th>
                            <th *ngIf="event.doublesEvent" scope="col" class="w-20">Partner</th>
                            <th *ngIf="event.doublesEvent" scope="col" class="w-20">Club</th>
                            <th *ngIf="event.doublesEvent" scope="col" class="w-10">Speelsterkte</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let team of group.teams">
                            <td class="align-middle">{{ team.player1 | fullName }}</td>
                            <td class="align-middle">{{ team.player1.club }}</td>
                            <td class="align-middle">{{ getStrength(team.player1.strength.valueOf()) }}</td>
                            <td *ngIf="event.doublesEvent" class="align-middle">{{ team.player2 | fullName }}</td>
                            <td *ngIf="event.doublesEvent" class="align-middle">{{ team.player2?.club }}</td>
                            <td *ngIf="event.doublesEvent" class="align-middle">{{ getStrength(team.player2?.strength?.valueOf()) }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </mat-card-content>
                </mat-card>
              }
            }
          </mat-tab>
        }

        @if (tournament.status == 'ONGOING') {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>play_arrow</mat-icon>
              &nbsp;Actieve wedstrijden
              &nbsp;
              @if (this.activeMatches().length > 0) {
                <span class="badge text-bg-success">{{ this.activeMatches().length }}</span>
              }
            </ng-template>

            @if (this.activeMatches().length > 0) {
              <table class="table table-hover w-100 m-4">
                <thead>
                  <tr>
                    <th colspan="3">Wedstrijd</th>
                    <th>Onderdeel/Ronde</th>
                    <th>Start</th>
                    <th>Baan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (activeMatch of this.activeMatches(); track activeMatch.match.id) {
                    <tr>
                      <td class="align-middle">{{ activeMatch.match.team1 | teamText }}</td>
                      <td class="align-middle">-</td>
                      <td class="align-middle">{{ activeMatch.match.team2 | teamText }}</td>
                      <td class="align-middle">{{ activeMatch.group.name }} {{ activeMatch.round.name }}</td>
                      <td class="align-middle">{{ activeMatch.match.startTime | date: 'HH:mm' }}</td>
                      <td class="align-middle">{{ activeMatch.match.court }}</td>
                      <td nowrap class="align-middle">
                        <button class="align-baseline" mat-button (click)="editResult(activeMatch.match, activeMatch.group, activeMatch.round)">
                          <mat-icon>edit</mat-icon>
                          Uitslag invoeren
                        </button>
                        <button mat-icon-button [matMenuTriggerFor]="activeMatchMenu" [matMenuTriggerData]="{ match: activeMatch.match }" class="menu-button">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>

              <mat-menu #activeMatchMenu="matMenu">
                <ng-template matMenuContent let-match="match">
                  <button mat-button (click)="stopMatch(match)">
                    <mat-icon>stop</mat-icon>
                    Wedstrijd stoppen
                  </button>
                </ng-template>
              </mat-menu>

            } @else {
              <h6 class="mt-3">Geen actieve wedstrijden</h6>
            }
          </mat-tab>
        }
        @if (tournament.status == 'ONGOING' || tournament.status == 'DRAWN') {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>list</mat-icon>
              &nbsp;Onderdelen
            </ng-template>

            <mat-tab-group animationDuration="0ms" disableRipple="true">
              <ng-container *ngFor="let event of tournament.events">
                <ng-container *ngFor="let group of event.groups">
                  <mat-tab label="{{group.id}}">
                    <ng-template mat-tab-label>
                      <!--<mat-icon>list</mat-icon>&nbsp;-->
                      {{ group.name }}
                      &nbsp;
                      @if (getActiveMatchCountForGroup(group) > 0) {
                        <span class="badge text-bg-success">{{ getActiveMatchCountForGroup(group) }}</span>
                      }
                      @if (group.status != 'FINISHED' && groupOnlyHasFinishedRounds(group)) {
                        <button mat-icon-button [matMenuTriggerFor]="groupMenu" class="menu-button m-3">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #groupMenu="matMenu">
                          <button mat-menu-item (click)="finishGroup(group)">
                            <mat-icon>check</mat-icon>
                            Onderdeel afsluiten
                          </button>
                        </mat-menu>
                      }
                      @if (group.status == 'FINISHED') {
                        <button mat-icon-button [matMenuTriggerFor]="groupMenu" class="menu-button m-3">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #groupMenu="matMenu">
                          <button mat-menu-item (click)="reopenGroup(group)">
                            <mat-icon>check</mat-icon>
                            Onderdeel heropenen
                          </button>
                        </mat-menu>
                      }
                    </ng-template>
                    <mat-tab-group
                      animationDuration="0ms"
                      disableRipple="true"
                      [(selectedIndex)]="activeRoundTab"
                      (selectedTabChange)="onRoundTabChange($event)">
                      <ng-container *ngFor="let round of group.rounds; index as roundIndex">
                        <mat-tab label="{{round.id}}">
                          <ng-template mat-tab-label>
                            <mat-icon>{{ getRoundIcon(round.status) }}</mat-icon>
                            &nbsp;{{ round.name }}

                            <button mat-icon-button [matMenuTriggerFor]="activeRoundMenu" class="menu-button m-3">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #activeRoundMenu="matMenu">
                              @if (round.status == 'NOT_STARTED') {
                                <button mat-menu-item (click)="startRound(round)">
                                  <mat-icon>play_arrow</mat-icon>
                                  Ronde starten
                                </button>
                                <button mat-menu-item (click)="printMatchSheets(round)">
                                  <mat-icon>print</mat-icon>
                                  Wedstrijdbriefjes printen
                                </button>
                              }
                              <button mat-menu-item (click)="printRoundOverview(round)">
                                <mat-icon>print</mat-icon>
                                Rondeoverzicht printen
                              </button>
                              @if (round.status == 'IN_PROGRESS' && checkRoundComplete(round)) {
                                <button mat-menu-item (click)="finishRound(round)">
                                  <mat-icon>check</mat-icon>
                                  Ronde afsluiten
                                </button>
                              }
                              @if (group.status != 'FINISHED' && round.status == 'FINISHED' && (roundIndex + 1) == group.rounds.length) {
                                <button mat-menu-item (click)="newRound(group)">
                                  <mat-icon>playlist_add</mat-icon>
                                  Nieuwe ronde
                                </button>
                              }
                            </mat-menu>
                          </ng-template>

                          <h6 class="mt-3">Wedstrijden</h6>

                          @if (round.status == 'NOT_STARTED') {
                            <table class="table table-hover m-4 wide w-100">
                              <tbody>
                              <tr *ngFor="let match of round.matches">
                                <td class="align-middle w-team">{{ match.team1 | teamText }}</td>
                                <td class="align-middle w-sep">-</td>
                                <td class="align-middle w-team">{{ match.team2 | teamText }}</td>
                                <td class="align-middle w-fill"></td>
                              </tr>
                              @if (round.drawnOut) {
                                <tr>
                                  <td class="align-middle w-100" colspan="4"><b>Deze ronde uitgeloot:</b> {{ round.drawnOut | teamText }}</td>
                                </tr>
                              }
                              </tbody>
                            </table>
                          } @else if (round.status == 'IN_PROGRESS') {
                            <table class="table table-hover m-4 wide w-100">
                              <tbody>
                                <tr *ngFor="let match of round.matches">
                                  <td class="align-middle w-team" [ngClass]="{'winner': checkWinner(match) == 1}">{{ match.team1 | teamText }}</td>
                                  <td class="align-middle w-sep">-</td>
                                  <td class="align-middle w-team" [ngClass]="{'winner': checkWinner(match) == 2}">{{ match.team2 | teamText }}</td>
                                  <td class="align-middle w-fill">
                                    @if (match.status == 'NOT_STARTED') {
                                      <button mat-button (click)="startMatch(match)">
                                        <mat-icon>play_arrow</mat-icon>
                                        Wedstrijd starten
                                      </button>
                                    } @else if (match.status == 'IN_PROGRESS') {
                                      <button mat-button (click)="editResult(match, group, round)">
                                        <mat-icon>edit</mat-icon>
                                        Uitslag invoeren
                                      </button>
                                      <button mat-button (click)="stopMatch(match)">
                                        <mat-icon>stop</mat-icon>
                                        Wedstrijd stoppen
                                      </button>
                                    } @else if (match.status == 'FINISHED') {
                                      <div class="row result align-items-center">
                                        <span *ngFor="let game of match.games" class="col-2">{{ game.score1 }}-{{ game.score2 }}</span>
                                        @if (match.games.length == 2) {
                                          <span class="col-2"></span>
                                        }

                                        <button mat-icon-button [matMenuTriggerFor]="finishedMatchMenu" class="menu-button m-3">
                                          <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #finishedMatchMenu="matMenu">
                                          <button mat-menu-item (click)="editResult(match, group, round)">
                                            <mat-icon>edit</mat-icon>
                                            Uitslag bewerken
                                          </button>
                                        </mat-menu>
                                      </div>
                                    }
                                  </td>
                                </tr>
                                @if (round.drawnOut) {
                                  <tr>
                                    <td class="align-middle w-100" colspan="4"><b>Deze ronde uitgeloot:</b> {{ round.drawnOut | teamText }}</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          } @else if (round.status == 'FINISHED') {
                            <table class="table table-hover m-4 wide {{ this.groupIsDoublesType(group) ? 'w-100' : 'w-100' }}">
                              <tbody>
                                <tr *ngFor="let match of round.matches">
                                  <td class="align-middle w-team" [ngClass]="{'winner': checkWinner(match) == 1}">{{ match.team1 | teamText }}</td>
                                  <td class="align-middle w-sep">-</td>
                                  <td class="align-middle w-team" [ngClass]="{'winner': checkWinner(match) == 2}">{{ match.team2 | teamText }}</td>
                                  <td class="align-middle w-fill">
                                    <div class="row result align-items-center">
                                      <span *ngFor="let game of match.games" class="col-2">{{ game.score1 }}-{{ game.score2 }}</span>
                                      @if (match.games.length == 2) {
                                        <span class="col-2"></span>
                                      }
                                    </div>
                                  </td>
                                </tr>
                                @if (round.drawnOut) {
                                  <tr>
                                    <td class="align-middle w-100" colspan="4"><b>Deze ronde uitgeloot:</b> {{ round.drawnOut | teamText }}</td>
                                  </tr>
                                }
                              </tbody>
                            </table>
                          }

                          <h6 class="mt-3">Stand</h6>

                          <table class="table w-75 m-4">
                            <caption>Tussen haakjes de gemiddelden per gespeelde wedstrijd</caption>
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>
                                @if (event.doublesEvent) {
                                  Team
                                } @else {
                                  Speler
                                }
                              </th>
                              <th>Gespeeld</th>
                              <th>Punten</th>
                              <th>Games</th>
                              <th>Wedstrijdpunten</th>
                            </tr>
                            </thead>
                            <tbody class="table-group-divider">
                            <tr *ngFor="let entry of getStandingsForRound(round, group).entries">
                              <td class="align-middle">{{ entry.position }}</td>
                              <td class="align-middle">{{ entry.team | teamText }}</td>
                              <td class="align-middle">{{ entry.played }}</td>
                              <td class="align-middle">
                                @if (entry.played > 0 ) {
                                  {{ entry.points }} ({{ entry.points / entry.played | number: '1.0-2' }})
                                }
                              </td>
                              <td class="align-middle">
                                @if (entry.played > 0 ) {
                                  {{ entry.gamesWon }}-{{ entry.gamesLost}} ({{ (entry.gamesWon - entry.gamesLost) / entry.played | number: '1.0-2' }})
                                }
                              </td>
                              <td class="align-middle">
                                @if (entry.played > 0 ) {
                                  {{ entry.pointsWon }}-{{ entry.pointsLost }} ({{ (entry.pointsWon - entry.pointsLost) / entry.played | number: '1.0-2' }})
                                }
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </mat-tab>
                      </ng-container>
                    </mat-tab-group>
                  </mat-tab>
                </ng-container>
              </ng-container>
            </mat-tab-group>
          </mat-tab>
        }
        @if (tournament.status == 'ONGOING' || tournament.status == 'DRAWN') {
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon>settings</mat-icon>
              &nbsp;Beheer
            </ng-template>
            <mat-tab-group animationDuration="0ms" disableRipple="true">
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon>group</mat-icon>
                  &nbsp;Spelerslijst
                </ng-template>
                <table class="table table-hover w-75 m-4">
                  <thead>
                    <tr>
                      <th>Naam</th>
                      <th>Onderdelen</th>
                      <th>Kosten</th>
                      <th>Betaald</th>
                      <th>Aanwezig</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tournamentPlayer of tournament.tournamentPlayers">
                      <td>{{ tournamentPlayer.name }}</td>
                      <td>
                        <ng-container *ngFor="let event of tournamentPlayer.events">
                          {{ event }}&nbsp;
                        </ng-container>
                      </td>
                      <td>
                        {{ tournament.costsPerEvent[tournamentPlayer.events.length - 1] | currency:'EUR':'symbol':'1.2-2':'nl' }}
                      </td>
                      <td>
                        <mat-slide-toggle [(ngModel)]="tournamentPlayer.paid" (change)="playerPaid($event, tournamentPlayer.playerId)">
                          @if (tournamentPlayer.paid) {
                            Betaald
                          } @else {
                            Nog niet betaald
                          }
                        </mat-slide-toggle>
                      </td>
                      <td>
                        <mat-slide-toggle [(ngModel)]="tournamentPlayer.present" (change)="playerPresent($event, tournamentPlayer.playerId)">
                          @if (tournamentPlayer.present) {
                            Aanwezig
                          } @else {
                            Nog niet aanwezig
                          }
                        </mat-slide-toggle>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </mat-tab>
            </mat-tab-group>
          </mat-tab>
        }
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
}
