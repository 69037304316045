import {Component, OnInit} from '@angular/core';
import {Player} from "../../model/player";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {PlayerService} from "../../service/player.service";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RegistrationService} from "../../service/registration.service";
import {KeyValuePipe, NgFor, NgIf} from "@angular/common";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {EventRegistration, TournamentRegistration} from "../../model/tournamentRegistration";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {MatIcon} from "@angular/material/icon";
import {MatAnchor, MatButton, MatFabButton} from "@angular/material/button";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {Tournament} from "../../model/tournament";
import {TitleService} from "../../service/title.service";

@Component({
  selector: 'app-player-registrations',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatFormField,
    MatInput,
    MatLabel,
    NgFor,
    ReactiveFormsModule,
    FormsModule,
    MatCheckbox,
    NgIf,
    MatCardActions,
    RouterLink,
    KeyValuePipe,
    MatOption,
    MatSelect,
    MatIcon,
    MatFabButton,
    MatButton,
    MatAnchor,
    FullNamePipe
  ],
  providers: [
    FullNamePipe
  ],
  templateUrl: './player-registrations.component.html',
  styleUrl: './player-registrations.component.scss'
})
export class PlayerRegistrationsComponent implements OnInit {

  player: Player;
  tournamentRegistrations: TournamentRegistration[];
  allPlayers: Player[];
  showAll: boolean = false;

  waitingForBackend: boolean = false;

  constructor(
    private _snackBar: MatSnackBar,
    private playerService: PlayerService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private fullNamePipe: FullNamePipe
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.playerService.getById(Number(id)).subscribe(data => {
      this.player = data;
      this.titleService.setTitle(`Inschrijvingen van ${this.fullNamePipe.transform(this.player)}`);
    });
    this.registrationService.getTournamentRegistrationsByPlayerId(Number(id)).subscribe(data => {
      this.tournamentRegistrations = data;
    })
    this.playerService.getAll().subscribe(data => {
      this.allPlayers = data;
    });
  }

  saveRegistration(tournamentRegistration: TournamentRegistration, event: MouseEvent) {
    this.waitingForBackend = true;
    this.registrationService.saveTournamentRegistrations(tournamentRegistration, this.player.id).subscribe(data => {
      this.waitingForBackend = false;
      this._snackBar.open('Registratie opgeslagen.');
    });
  }

  updateModelWhenEventChecked(eventRegistration: EventRegistration, event: MatCheckboxChange) {
    if (event.checked) {
      eventRegistration.player = this.player.id;
    } else {
      eventRegistration.player = undefined;
    }
  }

  getRelevantPlayers(type: string): Player[] {
    let players: Player[];
    if ( (this.player.sex == 'M' && type == 'HD') ||
         (this.player.sex == 'V' && type == 'GD')
       ) {
      players = this.allPlayers.filter(player => player.sex == 'M' && player.id != this.player.id);
    } else {
      players = this.allPlayers.filter(player => player.sex == 'V' && player.id != this.player.id);
    }

    return players.sort((a, b) =>
      a.lastName < b.lastName ? -1 :
        b.lastName < a.lastName ? 1 :
          a.firstName < b.firstName ? -1 : 1);
  }


  getTournamentRegistrations(): TournamentRegistration[] {
    if (this.showAll) {
      return this.tournamentRegistrations;
    } else {
      return this.tournamentRegistrations.filter(t => (t.status == 'UPCOMING' || t.status == 'ONGOING'));
    }
  }

  protected readonly Player = Player;
  protected readonly EventRegistration = EventRegistration;
}
