<h2 mat-dialog-title>Kies een baan:</h2>
<mat-dialog-content>
  <button type="button" class="btn {{ data.availableCourts.indexOf(i + 1) < 0 ? 'btn-secondary' : 'btn-primary' }} btn-lg m-3"
          *ngFor="let item of [].constructor(data.totalCourts); let i = index"
          [disabled]="data.availableCourts.indexOf(i + 1) < 0" [mat-dialog-close]="i + 1">
    {{ i + 1 }}
  </button>
  <br>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onAnnulerenClick()">Annuleren</button>
</mat-dialog-actions>
