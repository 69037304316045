import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {MatCard, MatCardContent, MatCardHeader, MatCardTitle} from "@angular/material/card";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {AuthenticationService} from "../../authentication/authentication.service";
import {UserService} from "../../authentication/user.service";
import {LoginCredentials} from "../../authentication/loginCredentials";
import {User} from "../../authentication/user";
import {TitleService} from "../../service/title.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    MatCardTitle,
    MatCardContent,
    ReactiveFormsModule,
    MatFormField,
    MatButton,
    RouterLink,
    MatInput,
    NgIf,
    MatLabel,
    MatError,
    MatCard,
    MatCardHeader
  ],
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  private returnUrl: string;
  private ipAddress: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private userPersistenceService: UserService,
    private titleService: TitleService,
  ) {
    this.initializeForm();
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.titleService.setTitle("Inloggen");
  }

  login() {
    if (this.form.invalid) {
      return;
    }

    let loginCredentials = new LoginCredentials();
    loginCredentials = {
      ...loginCredentials,
      ...this.form.value,
    };

    this.authenticationService
      .login(loginCredentials)
      .subscribe(
        {
          next: (user: User) => {
            this.userPersistenceService.setUser(user);
            this.router.navigate([this.returnUrl]);
          }
        }
      );
  }

}
