<mat-card appearance="outlined" *ngIf="tournament">
  <mat-card-header>
    <h5>Loting voor {{ tournament.name }}</h5>
  </mat-card-header>
  <mat-card-content>
    <mat-card *ngFor="let event of tournament.events" appearance="outlined" class="m-3">
      <mat-card-header>
        <h6>Loting {{ TournamentEvent.getType(event.type) }}</h6>
      </mat-card-header>
      <mat-card-content>
        <mat-accordion multi="true">
          <mat-expansion-panel *ngFor="let group of this.event.groups">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ group.name }}&nbsp;<span class="badge text-bg-success">{{ group.teams.length }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="table {{ event.doublesEvent ? 'w-100' : 'w-50' }}">
              <thead class="thead-dark">
                <tr>
                  <th scope="col" class="w-25">Team 1</th>
                  <th scope="col" style="width: 5%">-</th>
                  <th scope="col" class="w-25">Team 2</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let match of group.rounds[0].matches">
                  <td class="align-middle">{{ match.team1 | teamText }}</td>
                  <td class="align-middle">-</td>
                  <td class="align-middle">{{ match.team2 | teamText }}</td>
                </tr>
              </tbody>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>

