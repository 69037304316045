import {Component, OnInit} from '@angular/core';
import {MatCard, MatCardContent, MatCardHeader} from "@angular/material/card";
import {TournamentService} from "../../service/tournament.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Tournament} from "../../model/tournament";
import {Round} from "../../model/round";
import {TeamPipe} from "../../pipes/team-pipe";
import {FullNamePipe} from "../../pipes/fullname-pipe";
import {Group} from "../../model/group";
import {NgForOf} from "@angular/common";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {TitleService} from "../../service/title.service";

@Component({
  selector: 'app-match-sheets',
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardContent,
    TeamPipe,
    NgForOf,
    MatFormField,
    MatInput,
    ReactiveFormsModule
  ],
  providers: [
    TeamPipe,
    FullNamePipe
  ],
  templateUrl: './match-sheets.component.html',
  styleUrl: './match-sheets.component.scss'
})
export class MatchSheetsComponent implements OnInit {

  tournament: Tournament;
  group: Group;
  round: Round;

  constructor(
    private tournamentService: TournamentService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService
  ) {
  }

  ngOnInit() {
    const tournamentId = this.route.snapshot.paramMap.get('id');
    let roundId = Number(this.route.snapshot.paramMap.get('roundId'));

    this.tournamentService.getById(Number(tournamentId)).subscribe(data => {
      this.tournament = data;
      for (let event of this.tournament.events) {
        for (let group of event.groups) {
          for (let round of group.rounds) {
            if (round.id == roundId) {
              this.group = group;
              this.round = round;
              this.titleService.setTitle(`Wedstrijdbriefjes ${this.group.name} ${this.round.name}`);
              return;
            }
          }
        }
      }
    });
  }

}
