export class TournamentValidation {
  id: number;
  validations : Validation[];
  eventValidations: EventValidation[];
}

export class EventValidation {
  eventId: number;
  validations: Validation[];
}

export class Validation {
  severity: string;
  message: string;

  static hasErrors(validations: Validation[] | undefined): boolean {
    if (validations == null) {
      return false;
    } else {
      return validations.filter(v => v.severity == 'ERROR').length > 0;
    }
  }

}
